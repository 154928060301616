import React from 'react';
import PropTypes from 'prop-types';
import { clickTracking } from '@bsd/ui-utils/tracking';
import { Formik, Form } from 'formik';
import {
  Button,
  Panel,
  Text,
  BodyCopy,
  FlowButtons,
  PasswordEssentials,
  Dialog,
  FormikField,
} from 'bsd-react-ui-kit';

import { passwordValidation } from '@bsd/ui-utils/passwordValidation';
import useToggle from 'hooks/useToggle';
import content from 'containers/ResetPassword/CreatePassword/content';

const PasswordForm = ({ user, values, isValid, submitCount }) => {
  const baseClass = 'bsd-create-password';

  const [isPasswordVisible, togglePasswordVisible] = useToggle(false);
  const inputType = isPasswordVisible ? 'text' : 'password';

  const showDialog = !isValid && submitCount > 0;

  return (
    <div className={baseClass}>
      <Text type="display">{content.title}</Text>

      <Dialog noClose show={showDialog} title={content.validation.dialog} />

      <Panel>
        <BodyCopy>{content.formHeader}</BodyCopy>
        <div className={`${baseClass}-input-row`}>
          <div className={`${baseClass}-password-input`}>
            <FormikField
              id="password"
              input={{
                name: 'password',
              }}
              label={content.passwordLabel}
              name="password"
              type={inputType}
            />

            <Button
              modifier="link"
              onClick={togglePasswordVisible}
              size="s"
              data-tracking={clickTracking(
                content.analytics.toggleShowPassword
              )}
            >
              {isPasswordVisible ? 'Hide' : 'Show'}
            </Button>
          </div>

          <FormikField
            id="confirmPassword"
            input={{
              name: 'confirmPassword',
            }}
            label={content.confirmPasswordLabel}
            name="confirmpassword"
            type={inputType}
          />
        </div>

        <PasswordEssentials
          confirmPassword={values.confirmPassword}
          password={values.password}
          userName={user}
        />
      </Panel>

      <FlowButtons alignment="end">
        <Button
          modifier="primary"
          type="submit"
          data-tracking={clickTracking(content.submitButton)}
        >
          {content.submitButton}
        </Button>
      </FlowButtons>
    </div>
  );
};

PasswordForm.propTypes = {
  user: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
  submitCount: PropTypes.number.isRequired,
};

const CreatePassword = ({ onSubmit, user }) => {
  const formikProps = {
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    initialErrors: {
      password: '',
      confirmPassword: '',
    },
    validate: (values) => {
      const errors = {};

      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = content.validation.nonMatch;
      }

      if (!values.confirmPassword) {
        errors.confirmPassword = content.validation.required;
      }

      // if password input is empty return early;
      // [passwordValidation] will return [null] if passed an empty password
      if (!values.password) {
        return {
          password: content.validation.required,
          confirmPassword: errors.confirmPassword,
        };
      }

      const { criteria } = passwordValidation({
        userName: user,
        password: values.password,
        confirmPassword: values.confirmPassword,
      });

      if (Object.values(criteria).includes(false)) {
        errors.password = content.validation.invalid;
      }
      return errors;
    },
    onSubmit,
  };

  return (
    <Formik {...formikProps}>
      {(props) => (
        <Form>
          <PasswordForm {...props} user={user} />
        </Form>
      )}
    </Formik>
  );
};

CreatePassword.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CreatePassword;
