const content = {
  title: 'Create New Password',
  formHeader: 'Enter and confirm your new password.',
  passwordLabel: 'New Password',
  confirmPasswordLabel: 'Confirm Password',
  submitButton: 'Reset Password',
  analytics: {
    toggleShowPassword: 'Toggle Show Password',
  },
  validation: {
    required: 'Required',
    invalid: 'Does not meet password essentials',
    nonMatch: 'Your passwords must match',
    dialog: 'The highlighted fields require your attention.',
  },
};

export default content;
