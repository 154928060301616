import React from 'react';
import { Panel, Text, BodyCopy, Link } from 'bsd-react-ui-kit';
import { clickTracking } from '@bsd/ui-utils/tracking';
import PropTypes from 'prop-types';
import content from 'containers/ForgotPassword/EmailConfirmation/content';

const EmailConfirmation = ({ email }) => {
  const baseClass = 'bsd-email-confirmation';

  return (
    <div className={baseClass}>
      <div className={baseClass + '-heading'}>
        <Text type="display" size="l">
          {content.header}
        </Text>
      </div>
      <Panel>
        <BodyCopy richText={content.body(email)} />

        <div className={`${baseClass}-contact-us`}>
          <BodyCopy richText={content.contact} />
          <Link
            data-tracking={clickTracking(content.contactUs)}
            className={`${baseClass}-contact-font`}
            target="_blank"
            to={content.contactUsUrl}
            rel="noreferrer"
            underline
          >
            {content.contactUs}
          </Link>
        </div>
      </Panel>
    </div>
  );
};

EmailConfirmation.propTypes = {
  email: PropTypes.string,
};

export default EmailConfirmation;
