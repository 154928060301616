import { useState } from 'react';

/**
 * Returns a tuple containing a stateful boolean value and a function to toggle it.
 */
const useToggle = (initialValue) => {
  const [currentValue, setValue] = useState(initialValue);
  const toggleValue = () => setValue((v) => !v);

  return [currentValue, toggleValue];
};

export default useToggle;
