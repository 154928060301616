/* eslint-disable import/no-anonymous-default-export */
const loggingBaseUrl =
  'https://dev.core-gateway.np.api-business.comcast.com/logging-api';
const loggingApiKey = 'K06lPBT0UikuQyiwW6MQ72tB9N4YORL5nWTN92O8';

export default {
  featureFlags: {
    useNewForgotPasswordEnabled: true,
  },
  fidoConfig: {
    headers: {
      'tracking-id': '',
      'x-api-key': 'tgnvhW9jET8J9pQT3iET36M3DvVYRgCw5EbBwSjo', // ??
    },
    dataApiConfig: {
      logging: {
        baseUrl: loggingBaseUrl,
        sensitiveFields: ['newPassword'],
      },
      passwordReset: {
        baseUrl: '/passwordReset',
        sensitiveFields: ['newPassword'],
      },
    },
  },
  redirectLinks: {
    forgotEmailUrl: 'https://business.comcast.com/account/forgot-username/',
  },
  loggerConfig: {
    appSettings: {
      applicationId: 'ResetPassword',
      apiKey: loggingApiKey,
      loggingEndpoint: {
        url: '/event',
        baseURL: loggingBaseUrl,
      },
    },
    environment: 'dev',
    visitorSessionId: '',
  },
  sessionDurationInMinutes: 20,
  globalNavConfig: {
    scriptUrl:
      'https://bsd-global-nav-ui-dev.np.digital.business.comcast.com/globalNav.js',
    environment: 'local',
  },
};
