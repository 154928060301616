import React from 'react';
import PropTypes from 'prop-types';

import { globalReducer, initialState } from 'context/global/globalReducer';

export const GlobalContext = React.createContext();
GlobalContext.displayName = 'GlobalContext';

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(globalReducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.node,
};

export const useGlobalContext = () => {
  const context = React.useContext(GlobalContext);

  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a GlobalProvider');
  }
  return context;
};
