import { fidoV2 as fido, unauthenticatedLogger } from '@bsd/ui-utils';
import initialConfig from 'config';

export const buildFidoConfig = (sessionId) => {
  const { fidoConfig, loggerConfig } = initialConfig;

  fidoConfig.headers['tracking-id'] = sessionId;
  loggerConfig.visitorSessionId = sessionId;
  unauthenticatedLogger.config(loggerConfig);

  return {
    ...initialConfig.fidoConfig,
    loggingProvider: unauthenticatedLogger,
  };
};

/**
 * Initialize Fido using sessionId
 */
export const initializeFido = (sessionId) => {
  const config = buildFidoConfig(sessionId);

  fido.init(config);
};
