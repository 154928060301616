import { useCallback, useState } from 'react';
import { sendPasswordResetEmail } from 'utils/requests';

const useSubmitEmail = () => {
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [confirmedEmailAddress, setconfirmedEmailAddress] = useState('');

  const submit = useCallback(async ({ emailAddress, captchaValue }) => {
    setSubmitting(true);
    try {
      const response = await sendPasswordResetEmail(emailAddress, captchaValue);

      if (response.status === 204) {
        setconfirmedEmailAddress(emailAddress);
        setSubmitted(true);
      } else {
        setError('Invalid response');
      }
    } catch (e) {
      setError(e);
    } finally {
      setSubmitting(false);
    }
  }, []);

  return { submit, submitting, error, submitted, confirmedEmailAddress };
};

export default useSubmitEmail;
