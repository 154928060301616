import React, { Suspense } from 'react';
import { Spinner } from 'bsd-react-ui-kit';

import ErrorBoundary from 'components/Error/ErrorBoundary';

import ResetPassword from 'containers/ResetPassword/ResetPassword';

const ResetPasswordPage = () => (
  <ErrorBoundary pageKey="reset-password">
    <Suspense fallback={<Spinner show />}>
      <ResetPassword />
    </Suspense>
  </ErrorBoundary>
);

export default ResetPasswordPage;
