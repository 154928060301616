import React from 'react';
import PropTypes from 'prop-types';

import { Panel, Text, Icon, BodyCopy } from 'bsd-react-ui-kit';

import content from 'containers/ResetPassword/ResetConfirmation/content';

const ResetConfirmation = ({ user }) => {
  const { firstName, lastName, emailAddress } = user;
  const baseClass = 'bsd-reset-confirmation';

  return (
    <div className={baseClass}>
      <div className={baseClass + '-heading'}>
        <Icon name="circle-check" modifier="success" size="xl" />
        <Text type="display" size="l">
          {content.header}
        </Text>
      </div>

      <Panel>
        <BodyCopy element="div">
          {content.firstLine(firstName, lastName)}
        </BodyCopy>
        <BodyCopy richText={content.secondLine(emailAddress)} />
      </Panel>
    </div>
  );
};

ResetConfirmation.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    emailAddress: PropTypes.string,
  }),
};

export default ResetConfirmation;
