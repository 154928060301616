import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { Sprite } from 'bsd-react-ui-kit';

import App from 'components/App';
import './main.scss';

ReactDOM.render(
  <BrowserRouter>
    <Sprite />
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

export default App;
