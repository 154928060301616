(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("classnames"), require("formik"), require("lodash/omit"), require("lodash/throttle"), require("prop-types"), require("react"), require("react-router-dom"));
	else if(typeof define === 'function' && define.amd)
		define("UIKit", ["classnames", "formik", "lodash/omit", "lodash/throttle", "PropTypes", "React", "react-router-dom"], factory);
	else if(typeof exports === 'object')
		exports["UIKit"] = factory(require("classnames"), require("formik"), require("lodash/omit"), require("lodash/throttle"), require("prop-types"), require("react"), require("react-router-dom"));
	else
		root["UIKit"] = factory(root["classnames"], root["formik"], root["lodash/omit"], root["lodash/throttle"], root["PropTypes"], root["React"], root["react-router-dom"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__80200__, __WEBPACK_EXTERNAL_MODULE__71987__, __WEBPACK_EXTERNAL_MODULE__15894__, __WEBPACK_EXTERNAL_MODULE__54579__, __WEBPACK_EXTERNAL_MODULE__23343__, __WEBPACK_EXTERNAL_MODULE__84639__, __WEBPACK_EXTERNAL_MODULE__54737__) {
return 