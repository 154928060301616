export const getAppBasePath = () =>
  window.location.hostname === 'business.comcast.com' ||
  window.location.hostname === 'business.stg.comcast.com' ||
  window.location.hostname === 'business.dev.comcast.com' ||
  window.location.hostname === 'business.qa.comcast.com' ||
  window.location.hostname === 'business.int.comcast.com' ||
  window.location.hostname === 'business.pqa.comcast.com'
    ? '/account/password-reset'
    : '';

export const resetPassword = getAppBasePath() + '/resetPassword';
export const forgotPassword = getAppBasePath() + '/forgotPassword';
