const content = {
  title: 'Reset Your password',
  formHeader:
    "Enter the email address you use to sign in to your Comcast Business account. We'll send an email to this address with a link to reset your password.",
  emailLabel: 'Email Address',
  forgotEmail: 'Forgot email address?',
  submitButton: 'Continue',
  validation: {
    required: 'Required',
    invalid: 'Invalid Format',
    dialog: 'The highlighted fields require your attention.',
    captcha: 'Required',
  },
};

export default content;
