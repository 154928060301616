import devConfig from 'config/dev';
import qaConfig from 'config/qa';
import intConfig from 'config/int';
import pqaConfig from 'config/pqa';
import stgConfig from 'config/stg';
import prodConfig from 'config/prod';
import localConfig from 'config/local';
const envMap = {
  localhost: 'local',
  'business.dev.comcast.com': 'dev',
  'password-reset-ui-dev.np.digital.business.comcast.com': 'dev',
  'business.qa.comcast.com': 'qa',
  'password-reset-ui-qa.np.digital.business.comcast.com': 'qa',
  'business.int.comcast.com': 'int',
  'password-reset-ui-int.np.digital.business.comcast.com': 'int',
  'business.pqa.comcast.com': 'pqa',
  'password-reset-ui-pqa.np.digital.business.comcast.com': 'pqa',
  'business.stg.comcast.com': 'stg',
  'password-reset-ui-stg.np.digital.business.comcast.com': 'stg',
  'business.comcast.com': 'prod',
  'password-reset-ui-prod.digital.business.comcast.com': 'prod',
};

const currentEnvironment = () => envMap[window.location.hostname];

export const getConfig = () => {
  switch (currentEnvironment()) {
    case 'dev':
      return devConfig;
    case 'qa':
      return qaConfig;
    case 'int':
      return intConfig;
    case 'pqa':
      return pqaConfig;
    case 'stg':
      return stgConfig;
    case 'prod':
      return prodConfig;
    default:
      return localConfig;
  }
};

export default getConfig();
