import { useState, useEffect } from 'react';

import { initializeFido } from 'utils/fidoConfig';
import useSessionId from 'hooks/useSessionId';

/**
 * Initializes fido and returns a bool indicating whether fido
 * has been successfully initialized
 */
const useFido = () => {
  const sessionId = useSessionId();
  const [isFidoReady, setIsFidoReady] = useState(false);

  useEffect(() => {
    if (sessionId) {
      initializeFido(sessionId);
      setIsFidoReady(true);
    }
  }, [sessionId]);

  return isFidoReady;
};

export default useFido;
