const content = {
  header: 'Password Reset Email Sent',
  body: (email) =>
    `We've sent an email to <strong>${email}</strong> with instructions to reset your password. If you do not receive this email within a few minutes, please check your spam folder.`,
  contact: 'Still need help?',
  contactUsUrl: 'https://business.comcast.com/support/contact-us/',
  contactUs: 'Contact Us',
};

export default content;
