import { fidoV2 as fido } from '@bsd/ui-utils';
import * as endpoints from 'utils/endpoints';
import { trackEvent, events } from 'utils/tracking';

export const resetPassword = async (newPassword, inviteId) => {
  const response = await fido.fetch(endpoints.resetPassword, {
    data: {
      newPassword,
      inviteId,
    },
  });

  if (response.status === 500) {
    trackEvent(events.RESET_PASSWORD_ERROR(response.body.messages));
  }
  if (response.status === 204) {
    trackEvent(events.RESET_PASSWORD_SUCCESS());
  } else {
    trackEvent(events.RESET_PASSWORD_FAILURE());
  }
  return response;
};

export const sendPasswordResetEmail = (emailAddress, captchaValue) =>
  fido.fetch(endpoints.sendPasswordResetEmail, {
    data: {
      emailAddress,
      captchaValue,
    },
  });

/**
 * Get details for user associated with given `inviteId`
 * @returns {Promise<{firstName: string, lastName: string, emailAddress: string}>} user
 * @param inviteId
 */
export const getUser = (inviteId) => {
  return fido.fetch(endpoints.getUser(inviteId)).then((res) => res.body);
};
