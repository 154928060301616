import { useEffect, useState } from 'react';
import { getUser } from 'utils/requests';

const useUser = (inviteId) => {
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUser(inviteId)
      .then(setUser)
      .then(() => setLoaded(true));
  }, [inviteId]);

  return { user, loaded };
};

export default useUser;
