import { useEffect, useState } from 'react';
import { updateSessionId } from 'utils';

const useSessionId = () => {
  const [sessionId, setSessionId] = useState('');

  useEffect(() => {
    if (!sessionId) {
      setSessionId(updateSessionId());
    }
  }, [sessionId, setSessionId]);

  return sessionId;
};

export default useSessionId;
