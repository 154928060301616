export const types = {
  ERROR_CLEAR: 'ERROR_CLEAR',
  ERROR_SET: 'ERROR_SET',
  LOADING_SET: 'LOADING_SET',
};

export const initialState = {
  isLoading: false,
  error: {},
};

export const clearError = () => ({
  type: types.ERROR_CLEAR,
  meta: {
    error: initialState.error,
  },
});

/**
 * Sets global error state, which is mapped to the `Dialog` rendered in the layout component
 * @param {string} error.heading - the heading of the error
 * @param {string} error.message - the content of the error
 * @param {boolean} error.noClose - set to true to hide the close icon on the error dialog
 * @param {boolean} error.noTitle - set to true to hide the title of the dialog
 */
const setError = ({
  heading,
  message,
  isFatal = false,
  noClose = false,
  noTitle = false,
}) => ({
  type: types.ERROR_SET,
  payload: {
    error: {
      heading,
      message,
      isFatal,
      noClose,
      noTitle,
      show: true,
    },
  },
});

export const setLoading = (status) => ({
  type: types.LOADING_SET,
  payload: {
    isLoading: status,
  },
});

export const actions = {
  clearError,
  setError,
  setLoading,
};

export const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ERROR_CLEAR:
      return {
        ...state,
        ...action.meta,
      };
    case types.ERROR_SET:
    case types.LOADING_SET:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
