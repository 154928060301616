(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("classnames"));
	else if(typeof define === 'function' && define.amd)
		define("UI-Utils", ["React", "classnames"], factory);
	else if(typeof exports === 'object')
		exports["UI-Utils"] = factory(require("react"), require("classnames"));
	else
		root["UI-Utils"] = factory(root["React"], root["classnames"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__4639__, __WEBPACK_EXTERNAL_MODULE__200__) => {
return 