import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ServiceError } from 'bsd-react-ui-kit';

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div className="bsd-page-error">
          <ServiceError />
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
