/**
 * Builds an endpoint that can be consumed by fido
 * @param  {string} url - The endpoint's URL
 * @param  {string} key - The endpoint type. ex. accountLinkingApi, usersApi
 * @param  {string} method - The HTTP method for the request. Defaults to 'POST'
 */
const buildEndpoint = (url, key, method = 'POST') => ({
  url,
  key,
  method,
});

const endpointTypes = {
  password: 'passwordReset',
};

export const sendPasswordResetEmail = buildEndpoint(
  '/sendEmail',
  endpointTypes.password
);

export const resetPassword = buildEndpoint('/', endpointTypes.password);

export const getUser = (inviteId) => {
  return buildEndpoint(`/${inviteId}`, endpointTypes.password, 'GET');
};
