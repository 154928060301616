import PageNotFound from 'components/Error/PageNotFound';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';

import * as paths from './paths';

const routes = [
  {
    path: paths.resetPassword,
    pageTitle: 'Reset Password',
    component: ResetPasswordPage,
  },
  {
    path: paths.forgotPassword,
    pageTitle: 'Forgot Password',
    component: ForgotPasswordPage,
  },
];

// PageNotFound should be the last route
routes.push({
  path: '*',
  pathKey: 'notFound',
  component: PageNotFound,
});

export default routes;
