import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { clickTracking } from '@bsd/ui-utils/tracking';
import validation from '@bsd/ui-utils/validation';

import {
  Button,
  Panel,
  Text,
  BodyCopy,
  FlowButtons,
  ReCaptcha,
  Dialog,
  Link,
  FormikField,
} from 'bsd-react-ui-kit';
import content from './content';

import config from 'config';

const EmailForm = ({ submitCount, setFieldValue, errors }) => {
  const { redirectLinks } = config;
  const baseClass = 'bsd-create-email';
  const showDialog =
    submitCount > 0 && Boolean(errors.emailAddress || errors.captcha);

  const isDev = window.location.hostname === 'localhost';

  return (
    <div className={baseClass}>
      <Text type="display">{content.title}</Text>
      <Dialog noClose show={showDialog} title={content.validation.dialog} />
      <Panel>
        <BodyCopy>{content.formHeader}</BodyCopy>
        <FormikField
          className={`${baseClass}-email-input`}
          id="emailAddress"
          input={{
            name: 'emailAddress',
          }}
          label={content.emailLabel}
          name="emailAddress"
          type="text"
        />
        <Link
          data-tracking={clickTracking(content.forgotEmail)}
          to={redirectLinks.forgotEmailUrl}
          external
          underline
        >
          {content.forgotEmail}
        </Link>

        <FormikField
          className={`${baseClass}-captcha`}
          customInput={
            <ReCaptcha
              mode={isDev ? 'dev' : 'prod'}
              onChange={(key) => setFieldValue('captchaValue', key)}
            />
          }
          id="recaptcha"
          input={{
            name: 'captchaValue',
          }}
          error={
            submitCount > 0 && errors.captcha ? content.validation.captcha : ''
          }
          label="Recaptcha field"
          type="custom"
          noLabel={true}
        />
      </Panel>

      <FlowButtons alignment="end">
        <Button
          modifier="primary"
          type="submit"
          data-tracking={clickTracking(content.submitButton)}
        >
          {content.submitButton}
        </Button>
      </FlowButtons>
    </div>
  );
};

EmailForm.propTypes = {
  errors: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
};

const EnterEmailAddress = ({ onSubmit }) => {
  const formikProps = {
    initialValues: {
      emailAddress: '',
      captchaValue: '',
    },
    initialErrors: {
      emailAddress: '',
      captchaValue: '',
    },
    validate: (values) => {
      const errors = {};
      const isInvalidEmailFormat = validation.email(values.emailAddress);
      const criteria = isInvalidEmailFormat === undefined;

      if (!values.emailAddress) {
        errors.emailAddress = content.validation.required;
      } else if (!criteria) {
        errors.emailAddress = content.validation.invalid;
      }
      if (!values.captchaValue) {
        errors.captcha = content.validation.captcha;
      }
      return errors;
    },
    onSubmit,
  };

  return (
    <Formik {...formikProps}>
      {(props) => (
        <Form>
          <EmailForm {...props} />
        </Form>
      )}
    </Formik>
  );
};

EnterEmailAddress.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default EnterEmailAddress;
