import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Dialog, ServiceError, Spinner } from 'bsd-react-ui-kit';

import { useGlobalContext } from 'context/global';
import useTrackPage from 'hooks/useTrackPage';

export default function Layout({ component: InnerComponent, pageTitle }) {
  const { state } = useGlobalContext();

  const { isLoading, error } = state;
  const hasError = Boolean(error.title || error.message);

  useTrackPage(pageTitle);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <div className="bsd-layout">
      <Spinner modifier="global" show={isLoading} />
      <div className="bsd-content">
        <Dialog
          defaultShow={hasError}
          key={`${error.title} ${error.message}`}
          message={error.message}
          modifier="error"
          title={error.title}
        />
        {error.isFatal ? <ServiceError /> : <InnerComponent />}
      </div>
    </div>
  );
}

Layout.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  pageTitle: PropTypes.string,
};
