import React, { Suspense } from 'react';
import { Spinner } from 'bsd-react-ui-kit';
import ErrorBoundary from 'components/Error/ErrorBoundary';
import ForgotPassword from 'containers/ForgotPassword/ForgotPassword';

const ForgotPasswordPage = () => (
  <ErrorBoundary pageKey="forgot-password">
    <Suspense fallback={<Spinner show />}>
      <ForgotPassword />
    </Suspense>
  </ErrorBoundary>
);

export default ForgotPasswordPage;
