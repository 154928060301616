import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { GlobalProvider } from 'context/global';

import Layout from 'containers/Layout/Layout';

import routes from 'routes';

export default function AppContainer() {
  const renderLayout = (route, props) => {
    const { component, pageTitle } = route;

    return (
      <GlobalProvider>
        <Layout component={component} pageTitle={pageTitle} {...props} />
      </GlobalProvider>
    );
  };

  const renderRoutes = () => (
    <Switch>
      {routes.map((route) => {
        const { exact, path } = route;

        return (
          <Route
            exact={exact}
            key={path}
            path={path}
            render={(props) => renderLayout(route, props)}
          />
        );
      })}
    </Switch>
  );

  return <main>{renderRoutes()}</main>;
}
