import { v4 as uuid } from 'uuid';
import { browser } from '@bsd/ui-utils';
import initialConfig from 'config';

/**
 * Creates a new object with single key removed
 * @param {string} key - The key to remove
 * @param {Object} object - The object to remove key from
 */
// eslint-disable-next-line no-unused-vars
export const omit = (key, { [key]: _, ...obj }) => obj;

export const updateSessionId = () => {
  const cookieName = 'cb_ucid';
  const sessionIdCookie = browser.getCookie(cookieName);
  const sessionId = sessionIdCookie || uuid();
  const maxAge = initialConfig.sessionDurationInMinutes * 60;

  document.cookie = `${cookieName}=${sessionId}; max-age=${maxAge}; domain=comcast.com; secure`;
  return sessionId;
};

/**
 * Appends stylesheet link to the document,
 * and optionally executes provided callback once the resource is loaded
 *
 * @param {string} href - link to css resource
 * @param {function} onLoad - callback to execute when resource is loaded
 */
export const loadStylesheet = (href, onLoad) => {
  if (!href || typeof href !== 'string') {
    throw new Error('href argument is required and needs to be a valid url');
  }

  const linkElement = document.createElement('link');

  linkElement.setAttribute('href', href);
  linkElement.setAttribute('rel', 'stylesheet');

  if (onLoad) {
    linkElement.onload = onLoad;
  }
  document.head.appendChild(linkElement);
};
