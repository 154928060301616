import React from 'react';
import { useLocation } from 'react-router-dom';

import { Spinner, ServiceError } from 'bsd-react-ui-kit';

import CreatePassword from 'containers/ResetPassword/CreatePassword/CreatePassword';
import ResetConfirmation from 'containers/ResetPassword/ResetConfirmation/ResetConfirmation';

import useUser from 'hooks/useUser';
import useSubmitNewPassword from 'hooks/useSubmitNewPassword';

const useSearchParam = (key) => {
  const { search } = useLocation();

  /* this is how you're supposed to do this,
    return new URLSearchParams(search).get(key);
  but c3po formats query params wrong
  e.g. ?token=inviteId?cid=conversationId
  so we need to split the string to remove the cid and return only the inviteId
  */
  // make sure the use didn't directly navigate to this page
  if (search !== undefined && search.trim() !== '') {
    // split to remove token key prefix from query param string
    const queryParams = search.split(`?${key}=`);
    // split again to remove CID from token string
    const splitQueryParams = queryParams[1].split('?');

    // return the token, leaving the cid in the dust
    return splitQueryParams[0];
  } else {
    return '';
  }
};

const ResetPassword = () => {
  const token = useSearchParam('token');
  const { user, loaded } = useUser(token);
  const { submit, submitting, error, submitted } = useSubmitNewPassword(
    decodeURIComponent(token)
  );

  if (!loaded || submitting) {
    return <Spinner show={true} />;
  }

  if (error || !user?.firstName || !user?.lastName || !user?.emailAddress) {
    return <ServiceError />;
  }

  return submitted ? (
    <ResetConfirmation user={user} />
  ) : (
    <CreatePassword onSubmit={submit} user={user} />
  );
};

export default ResetPassword;
